import logo from './logo.svg';
import {useForm, SubmitHandler} from "react-hook-form"
import './App.css';
import Title from './components/atoms/Title';
import Input from './components/atoms/Input';
import Submit from './components/atoms/Submit';
import React, {useState, useEffect, useRef} from 'react';

function App() {
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: {errors},
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
        shouldFocusError: false,
    });

    // スクロール位置の要素
    const scrollToRef = useRef(null);


    // 入力値を保持
    const [values, setValues] = useState({});
    // 画面状態
    const [pageStatus, setPageStatus] = useState('input_page');
    //借入可能メッセージ
    const [loanMessage, setLoanMessage] = useState({});
    // 借入可能金額を格納
    const [resultVal, setResultVal] = useState(0);

    // フォーム設定
    const Options = ({count}) => <option value={count}>{count}</option>;
    const age_lists = ['20歳～29歳', '30歳～39歳', '40歳～49歳', '50歳～59歳', '60歳～69歳', '70歳～75歳'];
    const job_lists = ["会社員", "自営業", "公務員", "契約社員・派遣社員", "パート・アルバイト", "学生", "年金受給者"];


    // 送信処理
    const onSubmit = (data) => {
        console.log(data);
        // data内のvalueが空の場合はエラーを返す
        if (data.age === "" || data.annual === "" || data.job === "" || data.price === "") {
            return;
        } else {
            setPageStatus('result_page');
            setValues(data);

            const newLoanMessage = {...loanMessage};
            // 計算
            let result = Math.floor((Number(data.annual) / 3) - Number(data.price));
            // 丸める
            result = Math.floor(result / 10) * 10;
            // 500万を超えていたら500万
            setResultVal((result > 500) ? 500 : result);
            scrollToRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    // リセット処理
    const handleClickResetBtn = () => {
        // ページをリロード
        window.location.reload();
        window.location.hash = "#loan";
    };


    return (

        <div id="loan" ref={scrollToRef}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="loan_title">
                    <Title title="お借入診断"/>
                </div>
                {pageStatus === 'input_page' &&
                    <div className="loan-form">
                        <dl className="loan-form_inner">
                            <dt className="loan-form_content">ご年齢</dt>
                            <dd>
                                <select
                                    className="loan-form_box"
                                    {...register("age", {required: true})}
                                >
                                    <option value="">選択してください</option>
                                    {
                                        age_lists.map((job_list, index) => {
                                            return (
                                                <option key={index}>{job_list}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.age && <p style={{color: 'red'}}>年齢を選択してください</p>}
                            </dd>
                        </dl>
                        <dl className="loan-form_inner">
                            <dt className="loan-form_content">ご本人様年収</dt>
                            <dd>
                                <input
                                    className="loan-form_box"
                                    {...register("annual", {required: true, pattern: /^[0-9]*$/})}
                                />
                                万円(税込)
                                {errors.annual?.types?.required &&
                                    <p style={{color: 'red'}}>ご本人様年収を入力してください</p>}
                                {errors.annual?.types?.pattern &&
                                    <p style={{color: 'red'}}>ご本人様年収が正しい形式ではありません。半角数字を入れてくださ
                                        い</p>}
                            </dd>
                        </dl>
                        <dl className="loan-form_inner">
                            <dt className="loan-form_content">ご職業</dt>
                            <dd>
                                <select
                                    className="loan-form_box"
                                    {...register("job", {required: true})}
                                >
                                    <option value="">選択してください</option>
                                    {
                                        job_lists.map((job_list, index) => {
                                            return (
                                                <option key={index}>{job_list}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors.job && <p style={{color: 'red'}}>職業を選択してください</p>}
                            </dd>
                        </dl>
                        <dl className="loan-form_inner">
                            <dt className="loan-form_content">他社お借入額</dt>
                            <dd>
                                <input
                                    className="loan-form_box"
                                    {...register("price", {required: true, pattern: /^[0-9]*$/})}
                                />
                                万円
                                {errors.price?.types?.required &&
                                    <p style={{color: 'red'}}>他社お借入額を入力してください</p>}
                                {errors.price?.types?.pattern &&
                                    <p style={{color: 'red'}}>他社お借入額が正しい形式ではありません。半角数字を入れてください</p>}
                                <p className="font-small">※無担保ローン(住宅ローン・自動車ローンを除く)<br/>
                                    ※借入のない場合は0円でご入力ください。</p>
                            </dd>
                        </dl>
                        <div className="loan-form_attention">
                            注意事項<br/>
                            この診断結果は、お借入れをご検討される場合の目安です。実際のお申し込みの審査結果とは異なる場合がありますので、あらかじめご了承ください。
                        </div>

                        <div className="loan-form_btn">
                            <Submit type="submit" id="loan_submit" value="診断をする"/>
                        </div>
                    </div>
                }
                {
                    pageStatus === 'result_page' &&
                    <div id="loan-result">
                        <div className="loan-form-result">
                            <div className={resultVal ? 'loan-form' : 'loan-form loan-form--check'}>
                                <div className="loan-form_inner-check">
                                    {
                                        resultVal < 10
                                            ? (
                                                <>
                                                    <div className="loan-form_item">
                                                        <h3>ご入力いただいた情報だけでは、お借入れ可否を判断できませんでした。</h3>
                                                    </div>
                                                    <p>このままお申込みいただけましたら、ご融資可能かを回答いたします。</p>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <div className="loan-form_item">
                                                        <h3>{loanMessage.loan}</h3>
                                                        <p>ぜひこのままお申し込みください。</p>
                                                    </div>
                                                    <div className="loan-form_check_flex-box">
                                                        <div className="loan-form_check-yen">検討可能なお借入額</div>
                                                        <div>
                                                            {/* <span className="loan-form_check-span"></span> */}
                                                            <div
                                                                className="loan-form_check-number">〜<span>{resultVal}</span>万円
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                    }
                                </div>
                            </div>

                            <div className="loan-form_btn">
                                { /* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href="https://www.hokusen.jp/ws/entry/pre/loan/top"
                                   className="loan-form_button-red">今すぐお申し込み</a>
                            </div>


                            <div className="loan-form_btn">

                                <input type="reset" value="もう一度診断する" className="loan-form_button"
                                       onClick={() => {
                                           reset();
                                           setPageStatus('input_page');
                                           scrollToRef.current.scrollIntoView({behavior: 'smooth'});
                                       }}/>
                            </div>
                        </div>

                        <div className="loan-form">
                            <div className="loan_title">
                                <h2>ご入力内容の確認</h2>
                            </div>
                            <dl className="loan-form_check-inner">
                                <dt className="loan-form_content">ご年齢</dt>
                                <dd className="loan-form_check-dd">{values.age}</dd>
                            </dl>
                            <dl className="loan-form_check-inner">
                                <dt className="loan-form_content">ご本人様年収</dt>
                                <dd className="loan-form_check-dd">{values.annual}万円(税込)</dd>
                            </dl>
                            <dl className="loan-form_check-inner">
                                <dt className="loan-form_content">ご職業</dt>
                                <dd className="loan-form_check-dd">{values.job}</dd>
                            </dl>
                            <dl className="loan-form_check-inner">
                                <dt className="loan-form_content">他社お借入額</dt>
                                <dd className="loan-form_check-dd">{values.price}万円</dd>
                            </dl>
                        </div>

                        <div className="loan-form_btn">
                            <a href="/cashing/ouen.html"
                               className="loan-form_button-skyblue">OUENカードの詳細はこちら</a>
                        </div>
                    </div>
                }
            </form>
        </div>
    );
}

export default App;
